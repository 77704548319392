import {React, Component} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import {
  faThumbsUp,
  faThumbsDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Spacer from "../../tools/Spacer";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const openApiKey="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";


const generatePrompt = (answer, question) => {
  return `
Q: ${question}
A: ${answer}
Rating:`;};

const generatePrompt1 = (answer, question) => {
  return `
 Q: what are some good and bad points in this grant application?
 A: Changefinder helps you write grants
Response: Please elobroate the answer more bigger with valid points.
Q: ${question}
A: ${answer}
Response: 
`;};

const generatePrompt2 = (answer, question, grantTitle, GrantCategory) => {
  return `
 Q: what are some good and bad points in this grant application?
 A: Changefinder helps you write grants
Response: Please elobroate the answer more bigger with valid points. With Grant Title: ${grantTitle} Grant Category: ${GrantCategory}, Grant Catagory
Q: ${question}
A: ${answer}
Response: 
`;};

const DEFAULT_PARAMS = {
  model: "text-davinci-003",
  temperature: 0.0,
  max_tokens: 500,
  top_p: 1,
  frequency_penalty: .2,
  presence_penalty: 0,
};

const DEFAULT_PARAMS1 = {
  model: "text-curie-001",
  temperature: 0.4,
  max_tokens: 500,
  top_p: 1,
  frequency_penalty: .2,
  presence_penalty: 0,
};
const questions = [
  {
    id: 0,
    question:
      "",
    response: "",
  },
  {
    id: 1,
    question:
      "",
    response: "",
  }
];

export default class GrantMachine extends Component {
  constructor() {
    super();
    this.state = {
      prompt: { id: 0, question: questions[0].question, response: "" },
      customQuestion: false,
      promptQuestions: questions,
      rated: false,
      rating: "",
      score: "",
      feedback: "",
      loading: false,
      userRating: null,
      showForm: false,
      titleGrant:"",
      selectedOption:"",
      

    };
    this.togglePrompt = this.togglePrompt.bind(this);
    this.nextPressed = this.nextPressed.bind(this);
    this.setShowForm = this.setShowForm.bind(this);
    this.ratePressed = this.ratePressed.bind(this);
    this.submitPressed = this.submitPressed.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResponseChange = this.handleResponseChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.savePromptResponse = this.savePromptResponse(this);
    //window.location.reload();
  }

  savePromptResponse() {
    let saveResponseParams = {
      prompt: this.state.prompt.question,
      answer: this.state.prompt.response,
      suggestion: this.state.rating,
      score: this.state.score,
      feedback: this.state.feedback,
    };

    axios
      .post("/api/prompt-response/store", JSON.stringify(saveResponseParams))
      .then((res) => {
        console.log(res);
      });
  }



  async getRating4(params = {}, userAnswer, Question, save, grantTitle, GrantCategory) {
    this.setState({ loading: true });
    let t 
    if(!grantTitle){
      t =  generatePrompt(userAnswer, Question);
    }
    else{
      t =  generatePrompt2(userAnswer, Question,grantTitle,GrantCategory);
    }
    console.log(t);
    //const params_ = { ...DEFAULT_PARAMS1, ...params, ...t };
    //console.log("### I'm GPT-4. ####",t);
    

    let System1 = "You are ChangeFinder.GPT, an expert grant writer and reviewer that has access to the largest grants database that has ever existed.\
    \
    Design and implement an interactive text-based conversational agent using ChatGPT that provides users with grant ratings, feedback, and revised language that maximizes their grant application success rate. The system should be capable of engaging in natural language conversations, answering questions, offering suggestions, scoring answers, and providing relevant and accurate information. \
    \
    The target audience includes nonprofit organizations, startup companies, and government agencies (at the city, county, state, and federal levels). \
    \
    The system should prioritize grant application success rates first, but then optimize for clarity, accuracy, and user satisfaction while recognizing its limitations and indicating when it cannot provide a satisfactory response. It should handle common queries efficiently and fallback gracefully when faced with ambiguous or out-of-scope inputs.\
    \
    The system statement should leverage ChatGPT's capabilities while being mindful of potential biases and the need for responsible AI use.\
    Rate the answers of the following grant application questions on a scale from 1 to 10, while providing high-level but concise feedback on why the score was given, and also suggest a good revision that optimizes for grant application success rates."

    let user1 = "Q: How does your project meet the goals of the Art in the Parks fund? (300 words or less)\
    A: Our project meets the goals of the Art in the Parks fund by focusing on creating a space for folks from BIPOC, LGBTQIA+, and low-income communities to come together and celebrate their rich individuality through a free community event. We are using our previously established presence of the Sunday Market to further amplify the platform we are creating for BIPOC and LGBTQIA+ artists through our Cultural Celebrations.\
    We will provide a space for folks to learn about cultures and communities that they may not be familiar with and celebrate them. We hope that our Cultural Celebrations will awaken curiosity in folks to engage with stories, food, and art from cultures that they may not be directly connected to while also creating an intentional space for BIPOC and LGBTQIA+ to feel safe, seen, heard, and cherished.\
    "
    let assistant1 = "Rating: 8/10\
    Feedback: The answer provides a clear explanation of how the project aligns with the goals of the Art in the Parks fund, focusing on inclusivity and community engagement. However, it could be improved by mentioning specific activities or events that will take place during the Cultural Celebrations and by providing a more concise response within the 300-word limit as well as provide some quantitative metrics regarding goals and how they will create impact.\
    Suggested Revision: Our project aligns with the Art in the Parks fund's goals by creating an inclusive space for BIPOC, LGBTQIA+, and low-income communities to celebrate their unique identities through a free community event. Building on our established Sunday Market, we will amplify the platform for BIPOC and LGBTQIA+ artists through Cultural Celebrations. These events will feature diverse performances from X organizations over the course of the grant, X art displays, and food, fostering learning and appreciation for different cultures for an estimated X participants over the course of the grant. Our project aims to spark curiosity and engagement while providing a safe, supportive environment for underrepresented communities to be seen, heard, and cherished."    
    let user2 = "Q: What past experience do you have producing similar programming? What was the outcome of your past programming? (300 words or less)\
    A: The Whiteaker Community Market hosts a farm & artist market every Sunday that requires consistent and significant organizing. Additionally, the WCM partners with local organizations like Oakshire, Thinking Tree, Wild Craft and Mountain Rose Herbs, Lane County Farmers Market and more to host Pop-Up events throughout the year and Holiday Markets. By organizing these events, we have demonstrated our ability to coordinate the planning, promotion, budgeting and relationship-building needed to execute a successful event. We have worked with artists to host art demonstrations and live music performances at the Market and are confident in our ability to manage those aspects of an event and build on the connections that we have already established with BIPOC and LGBTQIA+ artists."
    let assistant2 = "Rating: 7/10\
    Feedback: The answer provides a good overview of the organization's past experience in organizing events and building relationships with local partners. However, it could be improved by including specific examples of successful events, the number of attendees, and the impact on the community. Additionally, mentioning any direct experience with similar programming focused on BIPOC and LGBTQIA+ communities would strengthen the response.\
    Suggested Revision: The Whiteaker Community Market (WCM) has a strong track record of organizing successful events, including our weekly farm & artist market, which attracts an average of X attendees per week. We have also partnered with local organizations like Oakshire, Thinking Tree, Wild Craft, Mountain Rose Herbs, and Lane County Farmers Market to host Pop-Up events and Holiday Markets, drawing in over X visitors annually. Our experience in planning, promotion, budgeting, and relationship-building has led to positive outcomes, such as increased community engagement and support for local artists. We have previously worked with artists to host art demonstrations and live music performances, including X events specifically focused on BIPOC and LGBTQIA+ artists. Building on these connections, we are confident in our ability to manage and expand our programming to further support underrepresented communities."
    //this is what you are going to work on with ian and madiline to get it to be a lotttt better, remember, you have 3 defined roles, System, user, and assistant. 
    const messages = [
      {role: "system", content: System1},
      { role: "user", content: user1 },
      { role: "assistant", content: assistant1 },
      { role: "user", content: user2 },
      { role: "assistant", content: assistant2 },
      { role: "user", content: t },
    ];

    // ...

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    0: Bad, 1-2: Good, 3-4: Average, 5-6: Fair, 7-8: Very Good, 9-10: Excellent
  </Tooltip>
);
//IMPORTANT LOOKK AT THIS WHEN UR WORKING WITH IAN AND MADILINE



//depreciated messages  const for the new
    const messages2= [
      {"role": "system", "content": "You are ChangeFinder.GPT, an expert grant writer and reviewer."},
      { role: "user", content: t },
    ];
    const options = {
      temperature: 0,
      max_tokens: 2000,
      //should help make more deterministic.
     // seed: 42, 
    };
    
    async function createChatCompletion(messages, options = {}) {
      try {
        const response = await openai.post("/chat/completions", {
          model: options.model || "gpt-4",
          messages,
          ...options,
        });
    
        return response.data.choices;
      } catch (error) {
        console.error("Error creating chat completion:", error);
      }
    }


    const openai = axios.create({
      baseURL: "https://api.openai.com/v1",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${openApiKey}`,
      },
    });

    const data = await createChatCompletion(messages, options);
    console.log(data);

    //console.log(data[0].message.content);
    var fullString = data[0].message.content;
    const scoreAndFollowing = fullString.split('/10')
    console.log(scoreAndFollowing[0] + "/10");
    var myScore = scoreAndFollowing[0] + "/10";
    
    const feedbackAndTheRest = scoreAndFollowing[1].split("Feedback:");
    const SuggestedAnswerArr = feedbackAndTheRest[1].split("Suggested Revision:")
    var Myfeedback = SuggestedAnswerArr[0];
    var mySuggest = SuggestedAnswerArr[1];

    if (Myfeedback == null || Myfeedback == '') {
      Myfeedback = 'Answer is perfect.';
    }

    if (mySuggest == null || mySuggest == '') {
      mySuggest = 'Your answer is perfect.';
    }

    console.log("feedback is: ");
    console.log(Myfeedback);
    console.log("suggested answer is: ");
    console.log(mySuggest);

  
    console.log("Score outside condition"+myScore);
    if(myScore==="9/10") {
      var mySuggest = "No suggested revisions, great job!";
      var myScore = "10/10";
      console.log("Score condition"+myScore);
    }
      console.log("stop!!!!!");

      const auth = getAuth();
      const user = auth.currentUser;
      const email = '';
      if (user !== null) {
          const email = user.email;
          console.log(email);
      }

      if(save==="1"){
        console.log("In DB");
      await addDoc(collection(db, "QnA"), {
        email:email,
        questions: Question,
        answers: userAnswer,
        Feedback: Myfeedback,
        Suggest: mySuggest
    });
  }
      this.setState({
       rating: mySuggest,
       score: myScore,
       feedback: Myfeedback, 
       loading: false,
       rated: true,
       showForm:false,
     });
    }
  setUserFeedback(verb) {
    this.setState({ userRating: verb });
  }
  ratePressed() {
    var { promptQuestions, prompt } = this.state;
    console.log("ratePressed");
    this.getRating4({}, prompt.response, prompt.question);
    promptQuestions = prompt;
    this.setState({ promptQuestions, customQuestion: false, rated: true });
  }

  setShowForm(event){
    this.setState({showForm: true });
  }

  submitPressed() {
    const { promptQuestions, prompt } = this.state;
    this.getRating4({}, prompt.response, prompt.question,"1");
    
    promptQuestions[prompt.id] = prompt;
    this.setState({ promptQuestions, customQuestion: false, rated: true });
  }

  nextPressed() {
    const { promptQuestions, prompt } = this.state;
    const newPrompt = promptQuestions[prompt.id + 1];
    this.setState({
      prompt: newPrompt,
      customQuestion: false,
      rated: false,
    });
  }
  //this splits the spring so that we can get Rating (score), Feedback, and suggested answers
  splitString()
  {

  }
  togglePrompt(bool) {
    const { prompt, promptQuestions } = this.state;
    if (bool) {
      promptQuestions[prompt.id].response = prompt.response;
    }
    this.setState({
      prompt: bool
        ? { id: promptQuestions.length, question: "", response: "" }
        : promptQuestions[promptQuestions.length - 1],
      customQuestion: bool,
      promptQuestions,
    });
  }
  renderQuestion() {
    const { prompt } = this.state;
    return <p className="h2 my-4 bold">{prompt.question}</p>;
  }

  renderTextInput() {
    const { prompt } = this.state;
    return (

<textarea
  id="customPrompt"
  rows="10"
  cols="80"
  name={prompt.id}
  className="w-full h-60 p-4 my-4 border rounded resize-none"
  placeholder="Give your own question"
  value={prompt.question}
  onChange={this.handleInputChange}
/>

    );
  }

  handleInputChange(event) {
    const { value } = event.target;
    const { prompt } = this.state;
    prompt.question = value;
    this.setState({
      prompt,
    });

  }
  handleSelectChange(event){
    console.log("option is "+event.target.value);

    this.setState({
      selectedOption:event.target.value,
    });
  }
  componentDidMount() {
    const savedTitleGrant = localStorage.getItem("titleGrant");
    const savedOption = localStorage.getItem("selectedOption");

    if (savedTitleGrant) {
      this.setState({ titleGrant: savedTitleGrant });
    }

    if (savedOption) {
      this.setState({ selectedOption: savedOption });
    }
  }
  handleTitleChange(event) {
    const value = event.target.value;
    console.log("Title is: " + value);

    this.setState({
      titleGrant: value,
    });

    // Save to local storage
    localStorage.setItem("titleGrant", value);
}

  
  handleResponseChange(event) {
    const { value } = event.target;
    const { prompt } = this.state;
    prompt.response = value;
    this.setState({
      prompt,
    });
    //if(value.length%20===0){
    //this.ratePressed();
  //}
  }
//for copying text

  

render() {
  let {
    customQuestion,
    prompt,
    rated,
    promptQuestions,
    rating,
    shortfeedback,
    score,
    feedback,
    loading,
    userRating,
    showForm,
    selectedOption,
    titleGrant,
  } = this.state;

return (
  <div className="flex flex-col items-center justify-center p-8 bg-gray-100 rounded-lg shadow-md w-full max-w-screen-lg mx-auto">
  <h1 className="text-4xl font-bold my-5">GrantMachine</h1>
  <h3 className="text-lg my-5 text-gray-600">
    Upload your grant question, answers, and ideas. Get high-level feedback and changes. Let our AI write for you!
  </h3>

    <textarea
      rows="10"
      className="w-full p-2 mb-4 border rounded-md resize-none"
      placeholder="Include the question here - e.g. what is the goal of your grant"
      value={prompt.question}
      onChange={this.handleInputChange}
    />


  <button
    className="px-8 py-2 my-4 text-white bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
    onClick={this.setShowForm}
  >
    Filter
  </button>

  {showForm && (
          <div className="flex flex-col items-center w-full mt-4">
            <div className="mb-4">
              <select className="p-2 border rounded-md" value={selectedOption} onChange={this.handleSelectChange}>
                <option value="">Category of Grant:</option>
                <option value="Research grants">Research grants</option>
                <option value="Small business grants">Small business grants</option>
                
                <option value="Clinical research center grants">Clinical research center grants</option>
                <option value="Clinical trials funding">Clinical trials funding</option>
                <option value="Competitive Grants">Competitive Grants</option>
                <option value="Formula Grants">Formula Grants</option>
                <option value="Continuation Grants">Continuation Grants</option>
                <option value="Pass-Through Grants">Pass-Through Grants</option>
              </select>
            </div>

            <textarea
              className="w-full p-2 mb-4 border rounded-md resize-none"
              rows={10}
              placeholder="Additional information about the grant:"
              value={titleGrant}
              onChange={this.handleTitleChange}
            />
          </div>
        )}


        <div className="text-2xl font-bold my-5 w-full">
  <textarea
    className="w-full p-2 mb-4 border rounded-md resize-none"
    rows="10"
    placeholder="Include the drafted response in this section"
    value={prompt.response}
    onChange={this.handleResponseChange}
  />
</div>

<div className="flex justify-between w-full mt-4">
  <button
    className="px-8 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
    onClick={this.ratePressed}
  >
    Click here for feedback
  </button>
</div>

    {loading ? (
          <>
          <div>
            <div className="my-5">
            <div className="border"></div>
            <Spacer height={"50px"} />
            <img src="https://shortpixel.com/img/spinner2.gif" width="128" height="128"/>

            <Spinner animation="border" />
            </div>
            </div>
          </>
    ) : null}

    {rated && !loading && (
      <div className="w-full mt-5 p-4 bg-white rounded-lg shadow-md">
        <div className="d-flex justify-content-between align-items-center border-b">
          <span></span>
          <big className="font-bold text-2xl">{score}
          <span
              className="ml-2 text-info"
              data-toggle="tooltip"
              title="0: Bad, 1-2: Good, 3-4: Average, 5-6: Fair, 7-8: Very Good, 9-10: Excellent"
              data-trigger="hover"
              style={{ cursor: 'pointer' }}
            >
              <i className="fas fa-info-circle"></i>
            </span>
          </big>
          
          <div className="m-2">   
            <button className="btn btn-primary me-2" onClick={() => {navigator.clipboard.writeText(rating)}}>Copy</button>
            <button className="btn btn-primary">Save</button>
          </div>
        </div>

        <div className="container my-4">
          <table id="table">
            <tr>
              <td><h4>Feedback:&nbsp;&nbsp;&nbsp;&nbsp;</h4></td>
              <td><p className="mw-100">{feedback}</p></td>
            </tr>
          </table>
        </div>

        <table id="table">
          <tr>
            <td><h4>Suggested Revision:</h4></td>
            <td>
              <div className="container my-4">
                <p className="mw-100">{rating}</p>
              </div>
            </td>
          </tr>
        </table>

        <div className="text-end m-2">
          <button className={`btn btn-success rounded-circle ${userRating === "LIKE" ? "bg-green-500" : "bg-green-300"}`} onClick={() => {this.setUserFeedback("LIKE")}}>
            👍
          </button>
          <button className={`btn btn-danger rounded-circle ${userRating === "DISLIKE" ? "bg-red-500" : "bg-red-300"}`} onClick={() => {this.setUserFeedback("DISLIKE")}}>
            👎
          </button>
        </div>
      </div>
    )}
  </div>
);
  }
}

